import React from 'react';
import { Col } from 'styled-bootstrap-grid';
import ProductCard from './productCard';

const ListProducts = props => {
  const otherProducts = props.products.map((product, index) => (
    <Col col={6} sm={6} md={4} key={index}>
      <ProductCard
        color={props.color}
        textColor={props.textColor}
        link={'/shop/product/' + product.node.uid}
        details={product.node.data}
        line={props.line}
      />
    </Col>
  ));

  return <>{otherProducts}</>;
};

export default ListProducts;
