import React from 'react';
import { graphql } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import ContentContainer from '../components/global/contentContainer';
import Helmet from 'react-helmet';

import Title from '../components/text/title';
import ListProducts from '../components/product/listProducts';

const Theme = {
  highlightColor: '#d38296',
  textColor: '#ffffff',
};

const ContainerTheme = {
  backgroundColor: '#ffdce6',
};

const Shop = ({ data }) => {
  return (
    <>
      <Helmet title={'Shop'} />
      <Row css={ContainerTheme}>
        <Col col={12}>
          <ContentContainer padding={'0 36px'}>
            <Title text="Shop" />
          </ContentContainer>
        </Col>

        <Col col={12}>
          <ContentContainer padding={'0 36px'} mobilePadding={'0 9px 40px 9px'}>
            <Row>
              <ListProducts
                color={Theme.highlightColor}
                textColor={Theme.textColor}
                products={data.allPrismicProduct.edges}
                line={true}
                productLimit={1}
              />
            </Row>
          </ContentContainer>
        </Col>
      </Row>
    </>
  );
};

export const query = graphql`
  query AllProducts {
    allPrismicProduct {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            subtitle
            image {
              copyright
              url
            }
            text {
              html
            }
            full_price
            initial_number_of_places
          }
        }
      }
    }
  }
`;

export default Shop;
