import { Decimal } from 'decimal.js-light';

const FormatPrice = (amount, currency) => {
  let price = new Decimal(amount).toNumber();
  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  });
  return numberFormat.format(price);
};

export default FormatPrice;
