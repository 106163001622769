import React from 'react';
import LandscapeImage from '../images/landscapeImage';
import { Link } from 'gatsby';
import styled from 'styled-components';
import formatPrice from '../utility/formatPrice';
import { Line } from '../utility/line';

const Product = styled.div`
  font-size: 20px;

  padding-bottom: 75px;

  height: 100%;

  & a {
    text-decoration: none;
    color: inherit;

    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover img {
      filter: grayscale(100);
      mix-blend-mode: multiply;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 35px;
  }

  @media (max-width: 576px) {
    font-size: 15px;
  }
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-top: 24px;

  & a {
    color: inherit;
  }

  &:nth-of-type(3) {
    padding-bottom: 27px;
  }
`;

const ProductTitle = styled.p`
  text-transform: uppercase;
  margin: 0;
`;

const ProductText = styled.p`
  margin: 0;
`;

const ReadMore = styled.p`
  margin: 0;
  color: ${props => props.color};
`;

const ProductCard = props => {
  return (
    <Product>
      <Link to={props.link}>
        <div>
          <LandscapeImage image={props.details.image.url} />
          <ProductDetails>
            <ProductTitle>{props.details.title.text}</ProductTitle>
            <ProductText>
              {formatPrice(props.details.full_price, 'gbp')}
            </ProductText>
          </ProductDetails>
        </div>
        <ReadMore color={props.color}>Read More...</ReadMore>

        {props.line === true && (
          <Line
            color={props.color}
            margin={'30px 0 0 0'}
            mobileMargin={'30px 0 0 0'}
          />
        )}
      </Link>
    </Product>
  );
};

export default ProductCard;
